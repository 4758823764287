<template>
  <div class="fd-ondemand">
    <component :is="FastLoading" />
    <component :is="HomeHeader" :pagina-atual="paginaAtual" />
    <component :is="LojaVirtualContent" />
  </div>
</template>

<script>
import { settings } from "@/config";

export default {
  name: "LojaVirtual",
  data: function() {
    return {
      paginaAtual: this.$route.name,
      fastTemplate: settings.fastTemplate,
    };
  },
  computed: {
    HomeHeader() {
      return () => import(`@/templates/${this.fastTemplate}/HomeExternoHeader.vue`);
    },
    LojaVirtualContent() {
      return () => import(`@/templates/${this.fastTemplate}/${this.paginaAtual}.vue`);
    },
    FastLoading() {
      return () => import(`@/templates/${this.fastTemplate}/components/Loading.vue`);
    },
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style></style>
